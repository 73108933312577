<template>
  <v-container fluid>
        <v-alert
        v-if="showDeleteInvoiceAlert"
      prominent
      class="alert-danger"
    >
      <v-row align="center">
        <v-col class="grow" cols="11">
         Are you sure you want to delete this invoice (ID: {{ invoiceIdToDelete }})?
        </v-col>
        <v-col cols="1">
             <v-btn        class="
                      font-weight-bold
                      placeholder-lighter
                      text-xs text-dark
                      btn-secondary
                      bg-gradient-light
                      mr-4
                      " @click="showDeleteInvoiceAlert = false">Close</v-btn>
          <v-btn        class="
                      font-weight-bold
                      placeholder-lighter
                      text-xs text-dark
                      btn-primary
                      bg-gradient-secondary
                      " @click="deleteInvoice(invoiceIdToDelete)">Yes</v-btn>
        </v-col>
      </v-row>
    </v-alert>
      <v-data-table
            :headers="computedHeaders"
            :items="invoices"
            :loading="tableLoading"
            :search="search"
            :items-per-page="1000"
            :sort-by="['id']"
            :sort-desc="[true]"
            item-key="id"
            class="elevation-1"
          >
          <template v-slot:top>
            <v-row class="mx-5">
                 <v-col v-if='permissions == "Admin" || permissions == "Countrymanager"'>
                     <v-select v-model="filterFreelancer" item-text="name" item-value="id"  @change="refreshInvoices()" :items="freelancers" clearable label="Filter Freelancer"> </v-select>
                  </v-col>
                <v-col>
                     <v-select  v-model="filterMonth" @change="refreshInvoices()" :items="monthOptions" clearable label="Filter Month"> </v-select>
                  </v-col>
                     <v-col>
                     <v-select v-model="filterYear" @change="refreshInvoices()" :items="yearOptions" clearable label="Filter Year"> </v-select>
                  </v-col>
            </v-row>
            <v-row class="mx-5" v-if='permissions == "Admin" || permissions == "Countrymanager"'>
              <v-col>
                     <v-select  v-model="freelancer" item-text="name" item-value="id" :items="freelancers" clearable label="Freelancer"> </v-select>
                  </v-col>
                <v-col>
                     <v-select  v-model="month" :items="monthOptions" clearable label="Month"> </v-select>
                  </v-col>
                     <v-col>
                     <v-select v-model="year" :items="yearOptions" clearable label="Year"> </v-select>
                  </v-col>
            <v-col>
            <v-btn  color="#fff"
                class="ms-auto btn-info bg-gradient-primary border-radius-md mt-4
                      placeholder-lighter
                      text-xs
                      py-5
                      px-6
                      mt-6
                      mb-2
                      ms-2
                "
                 @click="generateInvoice()">
                 
                 (Re)Generate invoice
                 </v-btn>

            </v-col>
            </v-row>
        
          </template>
      <template v-slot:item.date="{ item }" >
                 {{ item.date.split("T")[0].split('-')[2] }}-{{ item.date.split("T")[0].split('-')[1] }}-{{ item.date.split("T")[0].split('-')[0] }}
            </template>
               <template v-slot:item.view_invoice="{ item }" >

                <v-btn text @click="openInvoice(item.id)">Open Invoice</v-btn>
          </template>
             <template v-slot:item.details="{ item }" >
                <v-btn v-if='permissions == "Admin" || permissions == "Countrymanager"' @click="viewDetails(item.details)" text>View Details</v-btn>
            </template>
             <template v-slot:item.actions="{ item }" >

               <v-icon 
                                        v-if="permissions == 'Admin' || permissions == 'Countrymanager'"
                        
                            
                                    class="ml-4"
                                small  @click="() => {invoiceIdToSend = item.id; emailDialog = true}"
                >
                                        fa fa-envelope
                                    </v-icon>

             <v-icon   class="ml-4"
                small  @click="() => { editInvoiceDialog = true; invoiceDialogData = item; } ">
                        fa fa-edit
                    </v-icon>
                    <v-icon  class="ml-4"
                small  @click="() => { showDeleteInvoiceAlert = true; invoiceIdToDelete= item.id} "
>
                        fa fa-times
                    </v-icon>
                      
    
          

                    
          </template>
          <!-- TEST -->
          </v-data-table>   

          <v-dialog width="800px"
            
          
            v-model="invoiceDetailsDialog"
            
             >
             <v-card
                      class="mx-auto"
                      max-width="800px"
                      outlined
                    > 
                        <v-card-title> 
                          Invoice Details
                        </v-card-title>
                        <v-card-text>

                           <v-textarea v-html= invoiceDetailsText>

                        </v-textarea>

                        </v-card-text>
           
             </v-card>
          
          </v-dialog>  
            <v-dialog
                    v-model="emailDialog"
                    width="500"
                    >
        
                    <v-card>
                        <v-card-title class="text-h5 lighten-2">
                        Send Email to Accounts
                        </v-card-title>

                        <v-card-text>
                            Are you sure you want to send this to accounts?
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="() => { emailDialog = false; sendToAccounts()}"
                        >  
                        Yes
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>

                    <edit-invoice-card :dialog="editInvoiceDialog" v-if="editInvoiceDialog" :invoice="invoiceDialogData" @closeDialog="editInvoiceDialog = false" />


  </v-container>
</template>

<style>
canvas {
  width: 100% !important;
  height: 600px !important;
}

tbody tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

</style>

<script>
import EditInvoiceCard from './Components/EditInvoiceCard'

export default {
  components: {
          
          EditInvoiceCard

  },
  data: function() {

      return {
      dialog: false,
      showDeleteInvoiceAlert: false,
      tableLoading:  true,
      snackbar: false,
      search : "",
      snackbarMsg: "",
      freelancerId: "",
      invoiceIdToDelete: "",
      invoice: {},
      invoices: [],
      invoiceDetailsDialog: false,
      editInvoiceDialog: false,
      invoiceDialogData: {},
      headers: [
        {
          text: "Invoice ID",
          sortable: true,
          value: "id",
        },  
        { text: "Freelancer", value: "freelancer.name" },

        { text: "Invoice Date", value: "date" },
        { text: "Invoice Details", value: "details" },
        { text: "View Invoice", value: "view_invoice" },
        { text: "Mail Sent", value: "mail_sent" },
        { text: "Comment", value: "comment" },

        { text: "Actions", value: "actions" },

      ],
      freelancerHeaders:[
        {
          text: "Invoice ID",
          sortable: true,
          value: "id",
        },  

        { text: "Invoice Date", value: "date" },
        { text: "View Invoice", value: "view_invoice" },

      ],
      filterFreelancer: "",
      filterMonth: "",
      filterYear: "",
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      freelancer: null,
      freelancers: [],
      invoiceDetailsText: "",
      permissions: localStorage.getItem('permission'),
      monthOptions: [{text: "January" , value: 1} ,{text: "February" , value: 2},{text: "March" , value: 3},{text: "April" , value: 4},{text: "May" , value: 5},{text: "June" , value: 6}
        ,{text: "July" , value: 7},{text: "August" , value: 8},{text: "September" , value: 9},{text: "October" , value: 10},{text: "November" , value: 11},{text: "December" , value: 12}
      ],
      yearOptions: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2,new Date().getFullYear()-3],
      invoiceIdToSend: "",
      emailDialog: false,
    };
  },
  computed: {
   computedHeaders () {
      if(this.permissions != 'Admin' && this.permissions != 'Countrymanager'){

          return this.freelancerHeaders

      }  else{
        return this.headers
      }
   }
},
  created: function() {
        var year = this.$route.query.year
        var month = this.$route.query.month
        this.filterYear = year
        this.filterMonth = month

        if(year)
            this.filterYear =  Number(year)
        
        if(month)
           this.filterMonth = Number(month)

        if(this.permissions == 'Freelancer'){
            this.headers.splice(1,1)
            this.$route.query.freelancerId = localStorage.getItem('id')
        }


        if(this.$route.query.freelancerId){
              this.$store.dispatch('getFreelancerById', {freelancerId: this.$route.query.freelancerId})
              .then( async (response) => {

                    this.freelancer = response.data.data
                    this.filterFreelancer = this.freelancer

                    if(this.permissions == 'Freelancer'){
                       this.filterFreelancer = this.freelancer.id
                    }
              }  )
              .catch((e) => {
                this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

              })    

        }
        
        this.$store.dispatch('getInvoicesByFreelancerId', {freelancerId: this.$route.query.freelancerId, year: year, month: month})
        .then( async (response) => {

              this.invoices = response.data.data
              this.tableLoading = false
        }  )
        .catch((e) => {
                       this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

        })    

        if(this.permissions == 'Admin' || this.permissions == 'Countrymanager'){

            this.$store.dispatch('getFreelancers')
          .then( (response) => {
                
                this.freelancers = response.data.data
          }  )
          .catch((e) => {
                      this.$store.commit('showMessage',{ content: e.data.msg, color: 'warning' })

          })  
        }
    },
    methods: {
        refreshInvoices(){
               this.$store.dispatch('getInvoicesByFreelancerId', {freelancerId: this.filterFreelancer, year: this.filterYear, month: this.filterMonth})
              .then( async (response) => {

                  this.invoices = response.data.data
              })
            .catch((e) => {
                    this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

          })    
        },    
        openInvoice (invoiceId){
            var token = localStorage.getItem('token')
            var baseURL = process.env.VUE_APP_BASE_URL
            window.open(baseURL+"/invoice/pdf?id="+invoiceId+"&token="+token)     

        },
        
        generateInvoice(){
            if(!this.freelancer){
             
              this.$store.commit('showMessage',{ content: "Please select a freelancer", color: 'warning' })

            }else{
              this.$store.dispatch('generateInvoiceByFreelancerId', {freelancerId: this.freelancer, year: this.year, month:this.month})
                .then( async (response) => {
                       this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })

                    this.refreshInvoices()

                }  )
                .catch((e) => {
                       this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

                })   
              }
             
        },
        sendToAccounts(){
          console.log(this.invoiceIdToSend)
              this.$store.dispatch('sendEmailToAccounts', {invoiceId: this.invoiceIdToSend})
                .then( async (response) => {
                       this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })

                }  )
                .catch((e) => {
                       this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

                })   
        },
        deleteInvoice(invoiceId){
            this.showDeleteInvoiceAlert = false
            this.$store.dispatch('deleteInvoiceById', {invoiceId: invoiceId})
                .then( async (response) => {
                       this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })

                    this.refreshInvoices()
                }  )
                .catch((e) => {
                       this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

                })   
        },
        viewDetails(details){
          this.invoiceDetailsDialog = true
          this.invoiceDetailsText = details
        }
    }
     
  }

</script>
